import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { FaArrowsAltH, FaFireAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Image,
  Select,
  Text,
  Textarea,
  position,
} from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import Footer from "../Footer/Footer";
import Category from "../Screens/Category";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { Bars, Hearts } from "react-loader-spinner";
import "./MoviesDetails.css";
import { MdDriveFileMove } from "react-icons/md";
import { Avatar } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const MoviesDetails = () => {
  const [movies, setMovies] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [comment, setComment] = useState("");
  const toast = useToast();
  const [categories, setCategories] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [showAd, setShowAd] = useState(true);
  const [movie, setMovie] = useState([]);

  const getMovie = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/movies/get_files`);
      if (response.status === 200) {
        setMovie(response.data);
        setFilteredMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    getMovie();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      navigate(`/category-filter-movies/${selectedCategory}`);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  // console.log(params.id, 'kdkk')
  const getMovies = async () => {
    try {
      console.log("Fetching movies details for ID:", params.id);
      const response = await axios.get(
        `${mod.api_url}/movies/findBy/${params.id}`
      );
      if (response.status === 200) {
        console.log("Movie details fetched successfully:", response.data);
        setMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    if (params.id) {
      getMovies();
    }
  }, [params.id]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredResults = movies.filter((movie) =>
      movie.movieName.toLowerCase().includes(searchValue)
    );
    setFilteredMovies(filteredResults);
    setCurrentPage(1); // Reset currentPage when search term changes
  };

  if (!movies) {
    return (
      <div
        className="container-fluid d-flex justify-content-center align-items-center vh-100"
        style={{ background: "black" }}
      >
        <div className="row">
          <div className="col-md-12 text-center">
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const getYouTubeEmbedUrl = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
  };

  const handleAddComment = async () => {
    handleCardClick();
    if (comment.trim() === "") {
      return;
    }
    try {
      const response = await axios.post(
        `${mod.api_url}/movies/${params.id}/comments`,
        { comment }
      );
      if (response.status === 201) {
        toast({
          title: "Your Comment Sucessful.",
          status: "success",
          duration: 1000,
          isClosable: true,
          position: "top-right",
        });
        setComment("");
        // Optionally, you can add logic to update the comments list in the UI
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      toast({
        title: "Your Comment Not Done.",
        status: "warning",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const trailerUrl = getYouTubeEmbedUrl(movies.trailer);

  // const handleDownloadClickss = () => {
  //   const currentCount = localStorage.getItem("clickCount")
  //     ? parseInt(localStorage.getItem("clickCount"))
  //     : 0;

  //   let newCount = currentCount + 1;

  //   if (newCount > 3) {
  //     newCount = 1;
  //   }

  //   if (newCount === 1) {
  //     window.open("https://24jobsalert.com/download-movie/", "_blank");
  //     // window.location.href = "https://24jobsalert.com/download-movie/";
  //   } else if (newCount === 2) {
  //     window.open("https://24jobsalert.com/download-movie", "_blank");
  //     // window.location.href = "https://24jobsalert.com/download-movie";
  //   } else if (newCount === 3) {
  //     console.log("Video link saved to local storage:", movies.video);
  //   } else {
  //     console.log("Additional click actions here...");
  //   }
  // };

  const handleWatchNowClick = () => {
    // handleDownloadClickss();
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Watch Video</title>
        </head>
        <body style="margin:0; display:flex; align-items:center; justify-content:center; height:100vh; background-color:black;">
          <video controls autoplay style="width:100%; height:100%;">
            <source src="${movies.video}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </body>
      </html>
    `);
  };

  const handleDownloadClick = (videoUrl, movieName, bannerImg) => {
    console.log("videoUrl:", videoUrl);
    console.log("movieName:", movieName);
    console.log("bannerImg:", bannerImg);
    const currentCount = localStorage.getItem("clickCount")
      ? parseInt(localStorage.getItem("clickCount"))
      : 0;

    let newCount = currentCount + 1;

    if (newCount > 3) {
      newCount = 1; // Restart the cycle
    }

    localStorage.setItem("clickCount", newCount);

    // Save the video link, movie name, and banner image to local storage
    localStorage.setItem("movieVideoLink", videoUrl);
    localStorage.setItem("movieName", movieName);
    localStorage.setItem("bannerImg", bannerImg);

    if (newCount === 1) {
      window.open(
        "https://loanwaystore.com/zash-loan-app-se-loan-kaise-le/",
        "_blank"
      );
      // window.open("https://24jobsalert.com/download-movie", "_blank");
    } else if (newCount === 2) {
      window.open(
        "https://loanwaystore.com/zash-loan-app-se-loan-kaise-le/",
        "_blank"
      );
    } else if (newCount === 3) {
      console.log("Video link saved to local storage:", videoUrl);
      console.log("Movie name saved to local storage:", movieName);
      console.log("Banner image saved to local storage:", bannerImg);
    } else {
      console.log("Additional click actions here...");
    }
  };

  // Example usage:
  // handleDownloadClick({ videoUrl: "https://example.com/movie.mp4", movieName: "My Movie", bannerImg: "https://example.com/banner.jpg" });

  const formatSize = (sizeInKB) => {
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB < 1024) {
      return `${sizeInMB.toFixed(2)} MB`;
    }
    const sizeInGB = sizeInMB / 1024;
    return `${sizeInGB.toFixed(2)} MB`;
  };

  const handleCardClick = () => {
    window.open(
      "https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236 "
    );
    // setTimeout(() => {
    //   window.open("https://stake.com/?c=VvyhynH8", "_blank");
    // }, 4000);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowAd(false);
  //   }, 45000);
  //   return () => clearTimeout(timer);
  // }, []);

  const handleCloseAd = () => {
    setShowAd(false);
  };

  const profiles = [
    {
      name: "Aarav Sharma",
      avatarUrl: "https://randomuser.me/api/portraits/men/11.jpg",
    },
    {
      name: "Advik Patel",
      avatarUrl: "https://randomuser.me/api/portraits/men/12.jpg",
    },
    {
      name: "Vihaan Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/men/13.jpg",
    },
    {
      name: "Arjun Singh",
      avatarUrl: "https://randomuser.me/api/portraits/men/14.jpg",
    },
    {
      name: "Reyansh Kumar",
      avatarUrl: "https://randomuser.me/api/portraits/men/15.jpg",
    },
    {
      name: "Mohammad Khan",
      avatarUrl: "https://randomuser.me/api/portraits/men/16.jpg",
    },
    {
      name: "Ishaan Mishra",
      avatarUrl: "https://randomuser.me/api/portraits/men/17.jpg",
    },
    {
      name: "Rudra Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/men/18.jpg",
    },
    {
      name: "Shaurya Reddy",
      avatarUrl: "https://randomuser.me/api/portraits/men/19.jpg",
    },
    {
      name: "Sai Verma",
      avatarUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
      name: "Anaya Patel",
      avatarUrl: "https://randomuser.me/api/portraits/women/21.jpg",
    },
    {
      name: "Avni Singh",
      avatarUrl: "https://randomuser.me/api/portraits/women/22.jpg",
    },
    {
      name: "Aadya Reddy",
      avatarUrl: "https://randomuser.me/api/portraits/women/23.jpg",
    },
    {
      name: "Myra Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/women/24.jpg",
    },
    {
      name: "Kavya Sharma",
      avatarUrl: "https://randomuser.me/api/portraits/women/25.jpg",
    },
    {
      name: "Zara Singh",
      avatarUrl: "https://randomuser.me/api/portraits/women/26.jpg",
    },
    {
      name: "Ananya Kumar",
      avatarUrl: "https://randomuser.me/api/portraits/women/27.jpg",
    },
    {
      name: "Prisha Verma",
      avatarUrl: "https://randomuser.me/api/portraits/women/28.jpg",
    },
    {
      name: "Pari Mishra",
      avatarUrl: "https://randomuser.me/api/portraits/women/29.jpg",
    },
    {
      name: "Aanya Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/women/30.jpg",
    },
    {
      name: "Aarav Sharma",
      avatarUrl: "https://randomuser.me/api/portraits/men/31.jpg",
    },
    {
      name: "Advik Patel",
      avatarUrl: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
      name: "Vihaan Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/men/33.jpg",
    },
    {
      name: "Arjun Singh",
      avatarUrl: "https://randomuser.me/api/portraits/men/34.jpg",
    },
    {
      name: "Reyansh Kumar",
      avatarUrl: "https://randomuser.me/api/portraits/men/35.jpg",
    },
    {
      name: "Mohammad Khan",
      avatarUrl: "https://randomuser.me/api/portraits/men/36.jpg",
    },
    {
      name: "Ishaan Mishra",
      avatarUrl: "https://randomuser.me/api/portraits/men/37.jpg",
    },
    {
      name: "Rudra Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/men/38.jpg",
    },
    {
      name: "Shaurya Reddy",
      avatarUrl: "https://randomuser.me/api/portraits/men/39.jpg",
    },
    {
      name: "Sai Verma",
      avatarUrl: "https://randomuser.me/api/portraits/men/40.jpg",
    },
    {
      name: "Anaya Patel",
      avatarUrl: "https://randomuser.me/api/portraits/women/41.jpg",
    },
    {
      name: "Avni Singh",
      avatarUrl: "https://randomuser.me/api/portraits/women/42.jpg",
    },
    {
      name: "Aadya Reddy",
      avatarUrl: "https://randomuser.me/api/portraits/women/43.jpg",
    },
    {
      name: "Myra Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
      name: "Kavya Sharma",
      avatarUrl: "https://randomuser.me/api/portraits/women/45.jpg",
    },
    {
      name: "Zara Singh",
      avatarUrl: "https://randomuser.me/api/portraits/women/46.jpg",
    },
    {
      name: "Ananya Kumar",
      avatarUrl: "https://randomuser.me/api/portraits/women/47.jpg",
    },
    {
      name: "Prisha Verma",
      avatarUrl: "https://randomuser.me/api/portraits/women/48.jpg",
    },
    {
      name: "Pari Mishra",
      avatarUrl: "https://randomuser.me/api/portraits/women/49.jpg",
    },
    {
      name: "Aanya Gupta",
      avatarUrl: "https://randomuser.me/api/portraits/women/50.jpg",
    },
  ];

  let categoriesList = [];
  let languagesList = [];

  try {
    categoriesList = JSON.parse(movies.category);
    languagesList = JSON.parse(movies.language);
  } catch (error) {
    console.error("Failed to parse category or language:", error);
    // Fallback to splitting the string if it's not valid JSON
    categoriesList = movies.category.split(",");
    languagesList = movies.language.split(",");
  }
  const actorsArray = movies.actors.split(",");

  const firstPart = actorsArray.slice(0, 3).join(", ");
  const remainingPart = actorsArray.slice(3).join(", ");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MOVIESFLIX | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="MOVIESFLIX is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="MoviesFlix download,
MoviesFlix Bollywood,
MoviesFlix Hollywood,
MoviesFlix HD movies,
MoviesFlix TV shows,
MoviesFlix web series,
MoviesFlix 1080p,
MoviesFlix 720p,
MoviesFlix latest movies,
MoviesFlix new releases,
MoviesFlix free download,
MoviesFlix dubbed movies,
MoviesFlix Hindi movies,
MoviesFlix English movies,
MoviesFlix Tamil movies,
MoviesFlix Telugu movies,
MoviesFlix Punjabi movies,
MoviesFlix Marathi movies,
MoviesFlix South Indian movies,
MoviesFlix action movies,
MoviesFlix adventure movies,
MoviesFlix comedy movies,
MoviesFlix drama movies,
MoviesFlix horror movies,
MoviesFlix thriller movies,
MoviesFlix sci-fi movies,
MoviesFlix romance movies,
MoviesFlix family movies,
MoviesFlix animation movies,
MoviesFlix fantasy movies,
MoviesFlix documentary,
MoviesFlix crime movies,
MoviesFlix war movies,
MoviesFlix historical movies,
MoviesFlix mystery movies,
MoviesFlix box office hits,
MoviesFlix top rated movies,
MoviesFlix full movies,
MoviesFlix old movies,
MoviesFlix classics,
MoviesFlix trending movies,
MoviesFlix blockbuster movies,
MoviesFlix movie reviews,
MoviesFlix streaming,
MoviesFlix online watch,
MoviesFlix movie trailers,
MoviesFlix subtitles,
MoviesFlix dual audio,
MoviesFlix BluRay movies,
MoviesFlix torrents,
Latest movies,
New movie releases,
Free movie download,
HD movies online,
Watch movies online,
Full movies,
Best movies of 2023,
Best movies of 2024,
Top rated movies,
Popular movies,
Action movies,
Horror movies,
Comedy movies,
Romantic movies,
Thriller movies,
Sci-fi movies,
Family movies,
Adventure movies,
Animation movies,
Fantasy movies,
Drama movies,
Bollywood movies,
Hollywood movies,
Hindi movies,
English movies,
Dubbed movies,
Tamil movies,
Telugu movies,
Punjabi movies,
South Indian movies,
Classic movies,
Old movies,
Blockbuster movies,
Award-winning movies,
Movies with subtitles,
Kids movies,
Documentaries,
Movie trailers,
Movie reviews,
Movie streaming sites,
Online movie watch,
Download full movies,
BluRay movies,
1080p movies,
720p movies,
Dual audio movies,
Hindi dubbed movies,
Movie download sites,
Latest Hindi movies,
New Hollywood movies,
Top IMDb movies,"
        />
        <link rel="canonical" href="https://moviesflix.tech/" />
      </Helmet>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders onClick={handleCardClick} />
        <hr style={{ color: "white" }} />
        <div className="alert1" id="myDIV">
          <span
            className="closebtn"
            onclick="if (!window.__cfRLUnblockHandlers) return false; this.parentElement.style.display='none';"
          >
            ×
          </span>
          <center>
            <font size="3px">
              <b style={{ fontWeight: 400, color: "white" }}>
                Welcome to the <b className="hd">MOVIES</b>
                <b className="movie">FLIX</b>
                <b>
                  <a
                    href="https://telegram.me/moviehub4u143"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Join Our Telegram Channel
                  </a>
                </b>
                | 🔖 Remember our domain <b className="hd">MOVIES</b>
                <b className="movie">FLIX</b>
                to visit us.
              </b>
            </font>
          </center>
        </div>

        <hr style={{ color: "white" }} />

        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MOVIESFLIX: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <hr style={{ color: "white" }} />

        <Box width="100%" display="flex" flexWrap="wrap" overflowX="auto">
          <div style={{ textAlign: "center" }}>
            <Link to="https://telegram.me/moviehub4u143">
              <Button colorScheme="green">
                <Image
                  src="https://moviehub4u.org/TeleGram-Download.png"
                  width="100px"
                  height="30px"
                />
              </Button>
            </Link>

            {categories?.map((cat, index) => (
              <Button
                key={cat.id}
                as={Link}
                to={`/category-filter-movies/${cat.catName}`}
                marginRight={2}
                colorScheme={colors[index % colors.length]}
                margin={1}
              >
                {cat.catName}
              </Button>
            ))}
          </div>
        </Box>
        <hr style={{ color: "white" }} />

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-9">
            <div className="row" style={{ width: "100%", display: "contents" }}>
              <Text
                color="white"
                fontSize="x-large"
                fontWeight="600"
                textAlign="center"
                padding={3}
                onClick={handleCardClick}
              >
                {movies.movieName}

                <Text display="flex" marginTop="15px">
                  <Text
                    fontSize="small"
                    background="blue"
                    color="white"
                    padding="4px"
                  >
                    {" "}
                    <b> &#128197; 👉 </b>
                    {movies.createdAt.slice(0, 10)}
                  </Text>
                  {categoriesList.map((category, index) => (
                    <Text
                      fontSize="small"
                      background="white"
                      color="black"
                      padding="4px"
                      display="flex"
                      marginLeft="4px"
                    >
                      <MdDriveFileMove
                        style={{ marginRight: "5px", fontSize: "large" }}
                      />
                      {category}
                    </Text>
                  ))}
                </Text>
                <hr style={{ color: "white", width: "80%" }} />
                <Text fontSize="large" color="whitesmoke">
                  {" "}
                  {movies.movieName}
                </Text>
              </Text>

              <Center>
                <Image
                  className="movie_banner"
                  src={movies.bannerImg}
                  alt="Movie Banner"
                  height="auto"
                  padding="5px"
                  justifyContent="center"
                  borderLeft="3px solid rgb(255, 0, 0)"
                  borderRight="3px solid rgb(0, 128, 0)"
                  borderTop="3px solid rgb(255, 255, 0)"
                  borderBottom="3px solid rgb(255, 255, 0)"
                  onClick={handleCardClick}
                />
              </Center>

              <Center>
                <hr style={{ color: "white", width: "80%" }} />
              </Center>
              <Link to="/how-to-download">
                <Text
                  color="blue"
                  textAlign="center"
                  fontSize="xx-large"
                  fontWeight="600"
                >
                  [How To Download]
                </Text>
              </Link>

              <Link to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236">
                <Center>
                  <Image
                    src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                    alt="Ad Banner"
                    width="90%"
                    height="auto"
                  />{" "}
                </Center>
              </Link>

              <Center>
                <hr style={{ color: "white", width: "80%" }} />
              </Center>
            </div>
            <Center>
              <Box border="1px solid white" padding="10px">
                {movies?.videos?.[0] && (
                  <Center>
                    <Text
                      color="white"
                      fontSize="xx-large"
                      textAlign="center"
                      fontWeight="600"
                      display="block"
                    >
                      {movies.videos[0].videoName}
                    </Text>
                  </Center>
                )}

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    IMDB Rating:👉{" "}
                    <Text style={{ color: "red" }}>{movies.rating} ☆</Text>
                  </Text>
                </Center>

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Category:👉
                    {categoriesList.map((category, index) => (
                      <Text
                        key={index}
                        marginLeft={1}
                        marginRight={1}
                        background="green"
                        padding="3px"
                        fontFamily="poppins"
                        fontSize="small"
                      >
                        {category}
                      </Text>
                    ))}
                  </Text>
                </Center>

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Language:👉
                    {/* <Text style={{ color: "red" }}> {movies.language} </Text> */}
                    {languagesList.map((language, index) => (
                      <Text
                        key={index}
                        marginLeft={1}
                        marginRight={1}
                        background="blue"
                        padding="3px"
                        fontFamily="poppins"
                        fontSize="small"
                      >
                        {language}
                      </Text>
                    ))}
                  </Text>
                </Center>

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Upload Date:👉
                    <Text style={{ color: "white" }}>
                      {movies.createdAt.slice(0, 10)}{" "}
                    </Text>
                  </Text>
                </Center>
                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Quality:👉
                    <Text style={{ color: "red" }}>
                      WEB-DL 1080p | 720p | 480p
                    </Text>
                  </Text>
                </Center>

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Genre:👉
                    <Text style={{ color: "red" }} display="flex">
                      <Text color="blue" marginRight={1}>
                        {" "}
                        Action{" "}
                      </Text>{" "}
                      | Comedy | Sci-Fi
                    </Text>
                  </Text>
                </Center>

                <Center>
                  <Text
                    style={{
                      color: "white",
                      fontSize: "large",
                      textAlign: "center",
                      display: "inline-flex",
                      marginBottom: "-15px",
                    }}
                  >
                    Stars:👉
                    <Text style={{ color: "white" }}>
                      {firstPart}
                      {actorsArray.length > 3 && (
                        <>
                          <br />
                          {remainingPart}
                        </>
                      )}
                    </Text>
                  </Text>
                </Center>
              </Box>
            </Center>
            <Center>
              <hr style={{ color: "white", width: "80%" }} />
            </Center>
            <Center>
              <div className="row" style={{ width: "100%", marginLeft: "5px" }}>
                <Text
                  color="blue"
                  fontSize="xx-large"
                  fontWeight="600"
                  fontFamily="lemon"
                  textAlign="center"
                >
                  ..:: Screen-Shots ::..
                </Text>
                <Center>
                  <hr style={{ color: "white", width: "80%" }} />
                </Center>
                {movies?.screenShotImg?.map((ss, index) => (
                  <div key={index} className="col-md-6">
                    <Image src={ss} width="100%" height="auto" padding={1} />
                  </div>
                ))}
              </div>
            </Center>
            <hr style={{ color: "white" }} />
            <Text
              color="white"
              fontSize="x-large"
              textAlign="center"
              fontWeight="500"
            >
              {movies.movieName}
            </Text>
            <div className="row" style={{ width: "100%" }}>
              {movies?.videos?.map((videoUrl, index) => (
                <Center>
                  <div key={index} className="col-md-8">
                    <Text
                      color="red"
                      fontSize="large"
                      textAlign="center"
                      fontWeight="600"
                      display="block"
                    >
                      HINDI DUBBED | WEB-DL | {videoUrl.videoName}
                      <Text color="orange" fontSize="large">
                        Size in MB - {formatSize(videoUrl.size)}
                      </Text>
                      <Button
                        colorScheme="orange"
                        onClick={() =>
                          handleDownloadClick(
                            videoUrl.url,
                            movies.movieName,
                            movies.bannerImg
                          )
                        }
                      >
                        {(() => {
                          const slicedString = videoUrl.url.slice(93, 101);
                          if (typeof slicedString !== "string") {
                            return <div>Invalid</div>;
                          }
                          const result = slicedString.split(".")[0];
                          console.log(result, "fff");
                          return <>{result}</>;
                        })()}
                        | Download - {formatSize(videoUrl.size)}
                      </Button>
                      <Center>
                        {" "}
                        <hr style={{ color: "white", width: "50%" }} />
                      </Center>
                    </Text>
                  </div>
                </Center>
              ))}
            </div>
            <hr style={{ color: "white" }} />
            <Text
              color="white"
              textAlign="center"
              fontSize="x-large"
              padding={3}
            >
              {movies.movieName} →
            </Text>
            <Center>
              {trailerUrl ? (
                <iframe
                  className="trailer-video"
                  padding="30px"
                  src={trailerUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <>
                  <Text color="white">
                    <Hearts
                      height="80"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="hearts-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    Trailer Not Working
                  </Text>
                </>
              )}
            </Center>
            <hr style={{ color: "white" }} />
            <Box width="100%" p={5}>
              <Text color="red" textAlign="center" fontSize="large">
                {movies.movieName} → <strong>StoryLine : </strong>
              </Text>
              {movies.description.split(";").map((part, index) => (
                <Text key={index} color="white" textAlign="center" padding={2}>
                  {part.trim()}
                </Text>
              ))}
            </Box>
            <hr style={{ color: "white" }} />
            <Box width="100%" p={5}>
              <Text color="red" textAlign="center" fontSize="large">
                {movies.movieName} → <strong>Review : </strong>
              </Text>
              <Text color="white" textAlign="center" padding={2}>
                {movies.review.split(";").map((part, index) => (
                  <Text
                    key={index}
                    color="white"
                    textAlign="center"
                    padding={2}
                  >
                    {part.trim()}
                  </Text>
                ))}
              </Text>
            </Box>
            <hr style={{ color: "white" }} />
            <div className="alert">
              <h4 className="m1" style={{ color: "white" }}>
                If You Find Any Broken Link Then Report To Our
                <a
                  className="t1"
                  href="https://telegram.me/moviehub4u143"
                  style={{ textDecoration: "none" }}
                >
                  Official Telegram Channel
                </a>
              </h4>
              <h4 className="m2" style={{ color: "orange" }}>
                For Amazing on time Updates -
                <a
                  className="t2"
                  href="https://telegram.me/moviehub4u143"
                  style={{ textDecoration: "none" }}
                >
                  Official Telegram Channel
                </a>
              </h4>
              <h4 className="m3" style={{ color: "white" }}>
                Any Query? Kindly Comment on our
                <a
                  className="t1"
                  href="https://telegram.me/moviehub4u143"
                  style={{ textDecoration: "none" }}
                >
                  Official Telegram Channel
                </a>
                if you are having any issue our team will help you.
              </h4>
              <Center>
                <a href="https://telegram.me/moviehub4u143">
                  <img
                    src="/TeleGram-Download.png"
                    style={{ width: "300px" }}
                  />
                </a>
              </Center>
            </div>
            <hr style={{ color: "white" }} />
            <div
              className="col-md-12"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Center
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Textarea
                  placeholder="Enter your comment here"
                  style={{ width: "70%", marginBottom: "10px", color: "white" }}
                  value={comment}
                  onChange={handleCommentChange}
                />
                <Button
                  width="30%"
                  marginBottom="15px"
                  colorScheme="orange"
                  onClick={handleAddComment}
                >
                  Add Comment
                </Button>
              </Center>
            </div>
            <hr />
            <Text
              color="white"
              textAlign="center"
              fontSize="x-large"
              fontWeight="800"
            >
              ..::Comment::..
              {movies &&
                movies.comments &&
                movies.comments.map((com, index) => (
                  <div key={index}>
                    <div className="col-md-12">
                      <div
                        key={index}
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={profiles[index % profiles.length].avatarUrl}
                        />
                        <Text
                          marginLeft={3}
                          colorScheme="blue"
                          textAlign="left"
                          fontSize="large"
                          fontFamily="poppins"
                          fontWeight="200"
                        >
                          {profiles[index % profiles.length].name} : →
                        </Text>
                        <Text
                          marginLeft={1}
                          textAlign="left"
                          fontSize="large"
                          fontWeight="200"
                        >
                          {com.comment}
                        </Text>
                      </div>
                    </div>
                    <hr
                      style={{
                        background:
                          "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                        height: "5px",
                        border: "none",
                      }}
                    />
                  </div>
                ))}
            </Text>
            <hr style={{ color: "white" }} />
            {/* <Category /> */}

            <Text
              color="white"
              textAlign="center"
              fontFamily="lemon"
              fontSize="xx-large"
            >
              Latest Releases
            </Text>
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              onClick={handleCardClick}
            >
              {" "}
              {movie?.slice(0, 4)?.map((mov, index) => (
                <Card
                  key={index}
                  background="#252526"
                  borderLeft="3px solid rgb(255, 0, 0)"
                  borderRight="3px solid rgb(0, 128, 0)"
                  borderTop="3px solid rgb(255, 255, 0)"
                  borderBottom="3px solid rgb(255, 255, 0)"
                  m={{ base: 1, md: 1 }}
                  className="custom-card"
                  cursor="pointer"
                  onClick={() => navigate(`/movies-details/${mov._id}`)}
                >
                  <Image
                    className="banner-image"
                    src={mov.bannerImg}
                    alt="movie banner"
                    width="100%"
                    height="280px"
                    borderRadius="lg"
                  />
                  <Text
                    padding={2}
                    fontSize="large"
                    className="title"
                    color="white"
                    textAlign="center"
                    fontWeight="600"
                  >
                    {mov.movieName.slice(0, 150)}
                  </Text>
                </Card>
              ))}
            </Flex>
          </div>

          <div className="col-md-3">
            {" "}
            <Box width="100%">
              <Text fontSize="x-large" color="white" textAlign="center">
                Search Here!!!
              </Text>
              <Center>
                <br />
                <Link to="/">
                  <form className="d-flex" role="search">
                    <input
                      className="form-control me-2"
                      type="text"
                      placeholder="Search movies..."
                      value={searchTerm}
                      onChange={handleSearch}
                      style={{ width: "95%", marginBottom: "20px" }}
                    />
                  </form>
                  {/* <img src="hdhub4ulogo.png" alt="Logo" style={{ width: "200px" }} /> */}
                </Link>
              </Center>
            </Box>
            <Box width="100%">
              <Text fontSize="x-large" color="white" textAlign="center">
                Category !!!
              </Text>
              <Center>
                <Select
                  placeholder="Select Category"
                  color="white"
                  background="black"
                  width="95%"
                  onChange={handleChange}
                >
                  {categories.length > 0 &&
                    categories.map((cat) => (
                      <option
                        style={{ color: "white", background: "black" }}
                        key={cat.id}
                        value={cat.catName}
                      >
                        {cat.catName}
                      </option>
                    ))}
                </Select>
              </Center>
            </Box>
          </div>
        </div>

        <Footer />
      </div>
      <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        background="black"
        padding="10px"
        textAlign="center"
      >
        <Box position="absolute" top="10px" right="10px">
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236 "
            target="_blank"
          >
            <Button colorScheme="" onClick={() => setShowAd(false)}>
              X
            </Button>
          </Link>
        </Box>
        {showAd && (
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236"
            target="_blank"
          >
            <Box>
              <Image
                src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                alt="Ad Banner"
                width="100%"
                height="auto"
                className="betclass"
                onClick={() => setShowAd(false)}
              />
            </Box>
          </Link>
        )}
      </Box>
    </>
  );
};

export default MoviesDetails;
