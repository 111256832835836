import React, { useEffect } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { Box, Button, Center, Image, Text } from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt, FaOtter } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Joingroup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MOVIESFLIX | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="MOVIESFLIX is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="MoviesFlix download,
MoviesFlix Bollywood,
MoviesFlix Hollywood,
MoviesFlix HD movies,
MoviesFlix TV shows,
MoviesFlix web series,
MoviesFlix 1080p,
MoviesFlix 720p,
MoviesFlix latest movies,
MoviesFlix new releases,
MoviesFlix free download,
MoviesFlix dubbed movies,
MoviesFlix Hindi movies,
MoviesFlix English movies,
MoviesFlix Tamil movies,
MoviesFlix Telugu movies,
MoviesFlix Punjabi movies,
MoviesFlix Marathi movies,
MoviesFlix South Indian movies,
MoviesFlix action movies,
MoviesFlix adventure movies,
MoviesFlix comedy movies,
MoviesFlix drama movies,
MoviesFlix horror movies,
MoviesFlix thriller movies,
MoviesFlix sci-fi movies,
MoviesFlix romance movies,
MoviesFlix family movies,
MoviesFlix animation movies,
MoviesFlix fantasy movies,
MoviesFlix documentary,
MoviesFlix crime movies,
MoviesFlix war movies,
MoviesFlix historical movies,
MoviesFlix mystery movies,
MoviesFlix box office hits,
MoviesFlix top rated movies,
MoviesFlix full movies,
MoviesFlix old movies,
MoviesFlix classics,
MoviesFlix trending movies,
MoviesFlix blockbuster movies,
MoviesFlix movie reviews,
MoviesFlix streaming,
MoviesFlix online watch,
MoviesFlix movie trailers,
MoviesFlix subtitles,
MoviesFlix dual audio,
MoviesFlix BluRay movies,
MoviesFlix torrents,
Latest movies,
New movie releases,
Free movie download,
HD movies online,
Watch movies online,
Full movies,
Best movies of 2023,
Best movies of 2024,
Top rated movies,
Popular movies,
Action movies,
Horror movies,
Comedy movies,
Romantic movies,
Thriller movies,
Sci-fi movies,
Family movies,
Adventure movies,
Animation movies,
Fantasy movies,
Drama movies,
Bollywood movies,
Hollywood movies,
Hindi movies,
English movies,
Dubbed movies,
Tamil movies,
Telugu movies,
Punjabi movies,
South Indian movies,
Classic movies,
Old movies,
Blockbuster movies,
Award-winning movies,
Movies with subtitles,
Kids movies,
Documentaries,
Movie trailers,
Movie reviews,
Movie streaming sites,
Online movie watch,
Download full movies,
BluRay movies,
1080p movies,
720p movies,
Dual audio movies,
Hindi dubbed movies,
Movie download sites,
Latest Hindi movies,
New Hollywood movies,
Top IMDb movies,"
        />
        <link rel="canonical" href="https://moviesflix.tech/" />
      </Helmet>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders />
        <Text
          textAlign="center"
          color="red"
          fontFamily="lemon"
          fontWeight="600"
        >
          Just Remember 'ATOZMOVIES' to visit us if Blocked
        </Text>
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "ATOZMOVIES: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <Box display="flex" marginTop="10px" justifyContent="center">
          <FaFireAlt
            style={{
              color: "white",
              width: "40px",
              height: "40px",
              marginRight: "20px",
            }}
          />
          <Text color="white" fontFamily="lemon" fontSize="xx-large">
            Join Our Group !
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="500"
            textAlign="center"
          >
            ...::ATOZMOVIES::...
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="white"
            fontSize="x-large"
            fontWeight="600"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
          >
            Join Our Telegram Channel
          </Text>

          <div className="row" style={{ width: "100%" }}>
            <Center>
              <Link to="https://telegram.me/moviehub4u143" target="_blank">
                <img
                  src="download.jpeg"
                  style={{
                    width: "250px",
                    height: "80px",
                    borderRadius: "20px",
                  }}
                />

                <Text
                  textAlign="center"
                  color="blue"
                  fontSize="x-large"
                  marginTop="25px"
                >
                  Click To Join
                </Text>
              </Link>
            </Center>
          </div>

          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="700"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
          >
            ...::ATOZMOVIES::...
          </Text>
        </Box>

        <Footer />
      </div>
    </>
  );
};

export default Joingroup;
