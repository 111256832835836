import React, { useEffect, useState } from "react";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import Navbar from "../Header/Navbar";
import { Helmet } from "react-helmet";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const submitHandler = async () => {
    setLoading(true);

    // Validate user input
    if (!username || !password || !email) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    try {
      const requestData = {
        username,
        email,
        password,
      };

      const { data } = await axios.post(
        `${mod.api_url}/auth/signup`,
        requestData
      );
      // Handle success
      toast({
        title: "Sign-up Successful.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      navigate("/");
      setEmail("");
      setPassword("");
      setUsername("");
    } catch (error) {
      // Handle errors
      console.error("Registration Error:", error);
      toast({
        title: "Error during Register.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MOVIESFLIX | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="MOVIESFLIX is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="MoviesFlix download,
MoviesFlix Bollywood,
MoviesFlix Hollywood,
MoviesFlix HD movies,
MoviesFlix TV shows,
MoviesFlix web series,
MoviesFlix 1080p,
MoviesFlix 720p,
MoviesFlix latest movies,
MoviesFlix new releases,
MoviesFlix free download,
MoviesFlix dubbed movies,
MoviesFlix Hindi movies,
MoviesFlix English movies,
MoviesFlix Tamil movies,
MoviesFlix Telugu movies,
MoviesFlix Punjabi movies,
MoviesFlix Marathi movies,
MoviesFlix South Indian movies,
MoviesFlix action movies,
MoviesFlix adventure movies,
MoviesFlix comedy movies,
MoviesFlix drama movies,
MoviesFlix horror movies,
MoviesFlix thriller movies,
MoviesFlix sci-fi movies,
MoviesFlix romance movies,
MoviesFlix family movies,
MoviesFlix animation movies,
MoviesFlix fantasy movies,
MoviesFlix documentary,
MoviesFlix crime movies,
MoviesFlix war movies,
MoviesFlix historical movies,
MoviesFlix mystery movies,
MoviesFlix box office hits,
MoviesFlix top rated movies,
MoviesFlix full movies,
MoviesFlix old movies,
MoviesFlix classics,
MoviesFlix trending movies,
MoviesFlix blockbuster movies,
MoviesFlix movie reviews,
MoviesFlix streaming,
MoviesFlix online watch,
MoviesFlix movie trailers,
MoviesFlix subtitles,
MoviesFlix dual audio,
MoviesFlix BluRay movies,
MoviesFlix torrents,
Latest movies,
New movie releases,
Free movie download,
HD movies online,
Watch movies online,
Full movies,
Best movies of 2023,
Best movies of 2024,
Top rated movies,
Popular movies,
Action movies,
Horror movies,
Comedy movies,
Romantic movies,
Thriller movies,
Sci-fi movies,
Family movies,
Adventure movies,
Animation movies,
Fantasy movies,
Drama movies,
Bollywood movies,
Hollywood movies,
Hindi movies,
English movies,
Dubbed movies,
Tamil movies,
Telugu movies,
Punjabi movies,
South Indian movies,
Classic movies,
Old movies,
Blockbuster movies,
Award-winning movies,
Movies with subtitles,
Kids movies,
Documentaries,
Movie trailers,
Movie reviews,
Movie streaming sites,
Online movie watch,
Download full movies,
BluRay movies,
1080p movies,
720p movies,
Dual audio movies,
Hindi dubbed movies,
Movie download sites,
Latest Hindi movies,
New Hollywood movies,
Top IMDb movies,"
        />
        <link rel="canonical" href="https://moviesflix.tech/" />
      </Helmet>
      <div className="main" style={{ background: "black", height: "100vh" }}>
        <Navbar />
        <div className="login">
          <Container centerContent>
            <Center>
              <span className="navbar-brand">
                <Link to="/">
                  <img
                    src="hdhub4ulogo.png"
                    style={{ width: "250px", height: "auto" }}
                  />
                </Link>
              </span>
            </Center>

            <Text fontSize="large" color="white">
              Admin Register
            </Text>
          </Container>
          <Container centerContent>
            <Box
              p={3}
              width="100%"
              border="1px solid #e2e8f0"
              borderRadius="10px"
              borderWidth="1px"
            >
              <FormControl id="name" isRequired>
                <FormLabel color="white">Name</FormLabel>
                <Input
                  color="white"
                  placeholder="Enter Your Name"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel color="white">Enter Your Email</FormLabel>
                <Input
                  color="white"
                  placeholder="Enter Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel color="white"> Password</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Enter  Your Password"
                    value={password}
                    color="white"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme="pink"
                width="100%"
                marginTop={3}
                onClick={submitHandler}
              >
                Register
              </Button>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Register;
